// nodejs library that concatenates classes
import classnames from "classnames";
import CardsFooter from "components/Footers/CardsFooter.js";
// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import emailjs, { init } from "emailjs-com";
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import neo4j from "../../assets/img/ill/neo4j.svg";
import services from "../../assets/img/ill/services.png";

class Landing extends React.Component {
  state = {
    user_name: "",
    user_email: "",
    company_name: "",
    designation: "",
    phone_number: "",
    place: "",
    message: "",
    isShowThankyou: false,
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    init("user_xrtjytTxHSPNG6UqW7dWo");
    this.setState({
      isShowThankyou: false,
    });
    this.refs.main.scrollTop = 0;
  }

  onSubmit = (e) => {
    const {
      user_name,
      user_email,
      company_name,
      designation,
      phone_number,
      place,
      message,
    } = this.state;
    e.preventDefault(); // Prevents default refresh by the browser
    let templateParams = {
      user_name: user_name,
      user_email: user_email,
      company_name: company_name,
      designation: designation,
      phone_number: phone_number,
      place: place,
      message: message,
    };
    emailjs.send(
      "service_rd8ohb7",
      "contact_form",
      templateParams,
      "user_xrtjytTxHSPNG6UqW7dWo"
    );
    this.setState({
      user_name: "",
      user_email: "",
      company_name: "",
      designation: "",
      phone_number: "",
      place: "",
      message: "",
      isShowThankyou: true,
    });
  };

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-3 text-white">
                        VYugma <span>Where Open Source Meets Innovation</span>
                      </h1>
                      <p className="lead text-white">
                        The name Yugma is a word from the Sanskrit language
                        meaning "the state of being in unified collaboration".
                        VYugma means "We Collaborate".
                        <br />
                        VYugma is a technology consulting start-up based out of
                        Mumbai and focused on delivering solutions, services and
                        platforms based on open source and cloud technologies.
                        Our technology expertise with global and local industry
                        insights are well fused together to energize enterprises
                        and help them tackle their urgent business challenges.
                      </p>
                      <div className="btn-wrapper">
                        {/* <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href=""
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Components</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href=""
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Download React
                          </span>
                        </Button> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          {/* <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div> */}
                          <h6 className="text-primary text-uppercase">
                            CONSULTING AND IMPLEMENTATION SERVICES
                          </h6>
                          <p className="description mt-3">
                            VYugma’s core team has a long-standing experience in
                            bringing open source products and solution for
                            enterprises business needs. Leveraging that
                            experience, we plan to offer consulting services and
                            build a team.
                          </p>
                          <div></div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          {/* <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                            SOLUTIONS
                          </h6>
                          <p className="description mt-3">
                            Through these consulting assignments, we envisage to
                            build and bring platform for enterprise business and
                            government organizations that can be offered as
                            packaged services – preferably through SaaS model.
                          </p>
                          <br />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          {/* <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div> */}
                          <h6 className="text-warning text-uppercase">
                            DATA SCIENCE CONSULTING, AI SOLUTIONS AND SERVICES
                          </h6>
                          <p className="description mt-3">
                            We bring to you Data Science consulting, AI
                            Solutions and services in collaboration with our
                            partner Data Science Wizards <span>{`(DSW)`}</span>
                          </p>
                          <br />
                          <br/>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img alt="..." className="img-fluid floating" src={neo4j} />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div> */}
                    <h3>VYugma + Neo4J: A winning partnership</h3>
                    <p>
                      VYugma is official partner for Neo4J in India region.
                      Neo4j is the only enterprise-strength graph database that
                      combines native graph storage, advanced security, scalable
                      speed-optimized architecture, and ACID compliance to
                      ensure predictability and integrity of relationship-based
                      queries. That’s why it’s deployed by hundreds of Fortune
                      500 companies, government agencies, and NGOs.
                    </p>
                    <p>
                      Source: &nbsp;
                      <span>
                        <a
                          className="font-weight-bold text-warning mt-5"
                          href="https://neo4j.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Neo4J
                        </a>
                      </span>
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Uncompromised Performance, Reliability, and
                              Integrity.
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Revealing Richer Context to Drive Deeper Insights.
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              The Most Trusted. The Most Secure. The Most
                              Deployed.
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg alt="..." src={services} top />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      {/* <h4 className="display-3 font-weight-bold text-white">
                        Design System
                      </h4>
                      <p className="lead text-italic text-white">
                        The Arctic Ocean freezes every winter and much of the
                        sea-ice then thaws every summer, and that process will
                        continue whatever happens.
                      </p> */}
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-settings" />
                    </div> */}
                    <h3>
                      Maximizing the Value of Your Data with Graph Data
                      Platforms
                    </h3>
                    <p className="lead">
                      According to the world’s leading analyst and research firm
                      IDC’s Global DataSphere, more than 80 zettabytes (ZB) of
                      data will be created, captured, copied, and consumed in
                      the world this year. With the exponential growth in data
                      generation spurred by the demand for digitalization,
                      video, mobile, and edge technologies around the world, it
                      will become more difficult than ever to extract true value
                      from data. That’s where graph data platforms and the power
                      of connected data come in.
                    </p>
                    <p>
                      Source: &nbsp;
                      <span>
                        <a
                          className="font-weight-bold text-warning mt-5"
                          href="https://neo4j.com/whitepapers/maximizing-the-value-of-your-data-with-graph-data-platforms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Neo4J
                        </a>
                      </span>
                    </p>
                    <h3>
                      From Graph to Knowledge Graph: A Short Journey to
                      Unlimited Insights
                    </h3>
                    <p>
                      Most organizations are dealing with massive amounts of
                      data in various formats, types, and across systems. Their
                      challenge is to turn that data into intelligence that’s
                      useful for complex decision making. Unlike any other
                      technology, a knowledge graph gets richer as you add new
                      data. By layering semantics (meaning) onto graph data, you
                      create immediate value – which only grows and deepens with
                      time.
                    </p>
                    <p>
                      Source: &nbsp;
                      <span>
                        <a
                          className="font-weight-bold text-warning mt-5"
                          href="https://neo4j.com/whitepapers/knowledge-graphs-unlimited-insights/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Neo4J
                        </a>
                      </span>
                    </p>

                    <h3>Top Trends in Data and Analytics for 2021</h3>
                    <p>
                      In times of disruption, businesses lean hard on data and
                      analytics to drive smart decisions and inform strategy and
                      execution. But how can you drive rapid and effective
                      improvements in your use of data? With this Gartner report
                      revealing the top data and analytics trends for 2021, we
                      believe you’ll learn more about.
                    </p>
                    <p>
                      Source: &nbsp;
                      <span>
                        <a
                          className="font-weight-bold text-warning mt-5"
                          href="https://neo4j.com/whitepapers/gartner-top-trends-data-analytics/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Neo4J
                        </a>
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Meet Our Team</h2>
                  {/* <p className="lead text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={
                        require("assets/img/theme/team-1-800x800.jpg").default
                      }
                      style={{ width: "100px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Sandeep Khuperkar</span>
                        <small className="h6 text-muted">Founder</small>
                      </h5>
                      <br />
                      <div className="mt-3">
                        {/* <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button> */}
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/sandeepkhuperkar/"
                          target="_blank"
                          //onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={
                        require("assets/img/theme/team-2-800x800.jpg").default
                      }
                      style={{ width: "100px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Rohit Rajgor</span>
                        <small className="h6 text-muted">
                          Co-founder and Director of Business Strategy
                        </small>
                      </h5>
                      <div className="mt-3">
                        {/* <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button> */}
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/rohitrajgor/"
                          target="_blank"
                          //onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={
                        require("assets/img/theme/team-3-800x800.jpg").default
                      }
                      style={{ width: "100px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">
                          Pritesh <br />
                          Tiwari
                        </span>
                        <small className="h6 text-muted">
                          Director of Data Science
                        </small>
                      </h5>
                      <div className="mt-3">
                        {/* <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button> */}
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/pritesh-tiwari-b129a6a6/"
                          target="_blank"
                          //onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={
                        require("assets/img/theme/team-4-800x800.jpg").default
                      }
                      style={{ width: "100px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">
                          Neha <br />
                          Khuperkar
                        </span>
                        <small className="h6 text-muted">
                          Founder and Director of Operations
                        </small>
                      </h5>
                      <div className="mt-3">
                        {/* <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button> */}
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/neha-khuperkar-06a3ab21/"
                          target="_blank"
                          //onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={
                        require("assets/img/theme/team-5-800x800.jpg").default
                      }
                      style={{ width: "100px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">
                          Venkatesh Hariharan
                        </span>
                        <small className="h6 text-muted">Advisor</small>
                      </h5>
                      <br />
                      <div className="mt-3">
                        {/* <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button> */}
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/venky7/"
                          target="_blank"
                          //onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section
            className="section section-lg bg-gradient-default"
            id="contact"
          >
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Contact Us</h2>
                </Col>
                <p
                  className="mt-0"
                  style={{ fontWeight: "600", color: "aliceblue" }}
                >
                  Email : contact@vyugma.com | Phone : +91-9664056847 /
                  +91-8355951224
                </p>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <>
                      <CardBody className="p-lg-5">
                        {/* <h4 className="mb-1">Want to work with us?</h4> */}
                        <form id="contact-us" onSubmit={this.onSubmit}>
                          <FormGroup
                            className={classnames({
                              focused: this.state.nameFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-user-run5" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Your name"
                                type="text"
                                value={this.state.user_name}
                                onChange={(e) =>
                                  this.setState({ user_name: e.target.value })
                                }
                                onFocus={(e) =>
                                  this.setState({ nameFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ nameFocused: false })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-835" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email address"
                                type="email"
                                value={this.state.user_email}
                                onChange={(e) =>
                                  this.setState({ user_email: e.target.value })
                                }
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-835" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Company name"
                                type="text"
                                value={this.state.company_name}
                                onChange={(e) =>
                                  this.setState({
                                    company_name: e.target.value,
                                  })
                                }
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-835" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Designation"
                                type="text"
                                value={this.state.designation}
                                onChange={(e) =>
                                  this.setState({ designation: e.target.value })
                                }
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-phone" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Phone number"
                                type="number"
                                value={this.state.phone_number}
                                onChange={(e) =>
                                  this.setState({
                                    phone_number: e.target.value,
                                  })
                                }
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-835" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Place"
                                type="text"
                                value={this.state.place}
                                onChange={(e) =>
                                  this.setState({ place: e.target.value })
                                }
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Input
                              className="form-control-alternative"
                              cols="80"
                              name="name"
                              value={this.state.message}
                              onChange={(e) =>
                                this.setState({ message: e.target.value })
                              }
                              placeholder="Type a message..."
                              rows="4"
                              type="textarea"
                            />
                          </FormGroup>
                          <div>
                            <Button
                              block
                              className="btn-round"
                              color="default"
                              size="lg"
                              type="submit"
                            >
                              Send Message
                            </Button>
                          </div>
                        </form>
                      </CardBody>
                    </>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          {this.state.isShowThankyou ? (
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#0c79be",
              }}
            >
              Thank you for reaching us !
            </h3>
          ) : (
            ""
          )}
          {/* <Download /> */}
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
